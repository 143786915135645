:root {
  --main-app-layout: .9fr 2.4fr 1.1fr;
  --section-middle-margin-bottom: 50px;
  --section-padding: 25px;
}

body {
  max-width: none;
  padding: 0;
}

main {
  -ms-grid-columns: var(--main-app-layout);
  grid-template-columns: var(--main-app-layout);
  width: 100%;
  min-height: 100dvh;
}

section {
  box-shadow: none;
  padding: 0;
  padding: var(--section-padding);
  background: none;
  border-radius: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
}

section.left .burger-wrapper {
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  display: flex;
}

section.right {
  border-left: var(--subtle-border);
  position: sticky;
  top: 0;
  overflow-y: auto;
}

.card {
  background: rgba(var(--white-rgb), .3);
  box-shadow: var(--box-shadow);
  border-radius: 15px;
  width: 100%;
}

section.left .top, section.left .bottom {
  width: 100%;
}

section.left .top {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

section.left .top .logo object {
  width: 45px;
  height: 45px;
  margin: 0;
}

section.left ul {
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  margin-top: 30px;
  display: flex;
}

section.left ul li {
  width: 100%;
}

section.left li a, section.left li button.log-out {
  padding: 15px 20px;
}

section.left li a p {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

section.left li a.active {
  background: #fff6;
  border-radius: 15px;
  box-shadow: 1px 2px 5px #0000001a;
}

section.left li a.active p {
  color: rgba(var(--blue-rgb), 1);
  font-weight: 500;
}

section.left li a.active svg {
  filter: brightness(0) saturate() invert(65%) sepia(51%) saturate(332%) hue-rotate(181deg) brightness(96%) contrast();
}

section.left li a, section.left li button.log-out {
  align-items: center;
  gap: 15px;
  display: flex;
}

section.left li.has-button button {
  margin-top: 30px;
}

section.left li button.log-out {
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
}

section.middle {
  margin-bottom: var(--section-middle-margin-bottom);
}

section.middle .homepage, section.middle .deck, section.middle .profile, section.middle .leaderboard, section.middle .user, section.middle .friends {
  flex-direction: column;
  width: 100%;
  display: flex;
}

section.middle .deck .top-page .title-color {
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
}

section.middle .deck .top-page .color-prev {
  border-radius: 50px;
  flex-grow: 0;
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  box-shadow: 2px 2px 5px #0000001a;
}

section.middle .top-page .in-line {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

section.middle .deck .top-page p.description {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  display: -webkit-box;
  overflow: hidden;
}

section.middle .top-of-card {
  border-bottom: var(--subtle-border);
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  padding-bottom: 18px;
  display: flex;
}

section.middle .top-of-card h4 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

section.middle ul.top {
  display: -ms-grid;
  border-bottom: var(--subtle-border);
  border-top: var(--subtle-border);
  grid-template-columns: repeat(3, 1fr);
  gap: 0;
  padding: 12px 0 10px;
  display: grid;
}

section.middle .user ul {
  grid-template-columns: repeat(4, 1fr);
}

section.middle ul.top li {
  text-align: center;
}

section.middle ul.top li:nth-child(2) {
  border-left: 1px solid #0000001a;
  border-right: 1px solid #0000001a;
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

section.middle .user ul.top li:nth-child(3) {
  border-right: var(--subtle-border);
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

section.middle ul.top li p {
  margin-top: -7px;
}

section.middle ul.deck-list {
  display: -ms-grid;
  border: none;
  gap: 30px;
  margin: 0;
  padding: 0;
  display: grid;
}

section.middle ul.deck-list li {
  text-align: left;
  background: rgba(var(--white-rgb), 1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  box-shadow: var(--thin-box-shadow);
  border: none;
  border-radius: 10px;
  margin: 0;
  padding: 0;
}

section.middle ul.deck-list li:hover {
  box-shadow: 5px 5px 6px rgba(var(--charcoal-rgb), .2);
  transform: translateY(-10px);
}

section.middle ul.deck-list li a, section.middle .user ul.deck-list li {
  cursor: pointer;
  border-radius: 6px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  height: 100%;
  min-height: 175px;
  padding: 20px;
  display: flex;
  position: relative;
  bottom: 0;
}

section.middle ul.deck-list li .cards-due {
  text-align: center;
  background: #f4433640;
  border-radius: 50px;
  width: 50%;
  height: fit-content;
  font-size: 1.2rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}

section.middle ul.top .stat {
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: flex;
}

section.right {
  width: 100%;
}

section.right .top {
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
}

section.right .top img {
  box-shadow: var(--thin-box-shadow);
  border-radius: 50px;
  width: 55px;
  height: 55px;
  margin: 0 auto;
}

section.right .top .user-stats {
  justify-content: center;
  gap: 5px;
  width: 100%;
  display: flex;
}

section.right .top .stat {
  border: var(--subtle-border);
  text-align: center;
  background: #fff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  padding: 10px;
  display: flex;
}

section.right .top .top-of-stat {
  align-items: center;
  gap: 7px;
  display: flex;
}

section.right .top .top-of-stat i {
  font-size: 2rem;
}

section.right .top p.label {
  margin-top: -5px;
  font-size: 1rem;
}

section.middle .deck .top-page {
  align-items: center;
  gap: 15px;
  width: 100%;
  display: flex;
}

section.middle .deck .top-page .left {
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
}

section.middle .deck .top-page .left h3 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

section.middle .deck .top-page .left button {
  min-width: 22px;
  min-height: 22px;
  padding: 0;
}

section.middle .deck .top-page .right {
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  display: flex;
}

section.middle .deck .top-page .right svg {
  cursor: pointer;
  width: 25px;
  height: 25px;
}

section.middle .deck .top-page .right button {
  width: fit-content;
  margin: 0;
}

section.middle .deck ul.card-list {
  border: none;
  flex-direction: column;
  padding: 0;
  display: flex;
}

section.middle .deck ul.card-list li {
  background: rgba(var(--white-rgb), 1);
  box-shadow: var(--thin-box-shadow);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-align: center;
  border-radius: 15px;
  width: 100%;
  margin: 0 0 20px;
  position: relative;
}

section.middle .deck ul.card-list li:hover {
  box-shadow: 5px 5px 6px rgba(var(--charcoal-rgb), .2);
  transform: translateY(-10px);
}

section.middle .deck ul.card-list li:last-of-type {
  margin-bottom: 0;
}

section.middle .deck ul.card-list li.delete {
  background-color: #ff5a5a;
  height: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

section.middle .deck ul.card-list li button {
  width: 22px;
  height: 22px;
  padding: 0;
  position: absolute;
  top: 10px;
  right: 10px;
}

section.middle .deck ul.card-list li .front {
  border-bottom: var(--subtle-border);
  margin-bottom: 20px;
  padding-bottom: 20px;
}

section.middle .deck ul.card-list .widget {
  background: rgba(var(--turquoise-rgb), 1);
  box-shadow: var(--thin-box-shadow);
  border-radius: 50px;
  padding: 1px 10px;
  position: absolute;
  top: 0;
  left: 20px;
  transform: translateY(-50%);
}

section.middle .deck ul.card-list .widget p {
  color: rgba(var(--white-rgb), 1);
}

section.middle .deck .card li.in-review-true .widget {
  background: rgba(var(--pink-rgb), 1);
}

section.middle .deck .card li.future-review-true .widget {
  background: rgba(var(--green-rgb), .4);
}

section.middle .deck .card li.skeleton-container span.skeleton {
  margin: 7px auto;
}

p.notification {
  background: rgba(var(--white-rgb), 1);
  border-radius: 50px;
  width: 100%;
  padding: 10px 20px;
}

p.notification.hidden {
  display: none;
}

p.notification.in-progress {
  border: 2px solid rgba(var(--purple-rgb), 1);
  align-items: center;
  display: flex;
}

p.notification.complete {
  border: 2px solid rgba(var(--green-rgb), 1);
}

p.notification.error {
  color: rgba(var(--charcoal-rgb), 1);
  border: 2px solid rgba(var(--red-rgb), 1);
  margin: 0;
}

.notification.in-progress:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
}

section.middle .card-page {
  cursor: pointer;
  display: -ms-grid;
  -ms-grid-rows: 1fr 4fr 1fr;
  grid-template-rows: 1fr 4fr 1fr;
  align-items: center;
  gap: 15px;
  width: 75%;
  height: 100dvh;
  margin: 0 auto;
  display: grid;
}

section.middle .card-page .top-card-page {
  text-align: center;
  width: 100%;
  padding: 20px;
}

section.middle .card-page .top-card-page .name-deck {
  margin-bottom: 15px;
}

section.middle .card-page ul.top {
  width: 100%;
  padding: 7px 0 5px;
}

section.middle .card-page a.back {
  position: absolute;
  top: 10px;
  left: 10px;
}

section.middle .card-page .card {
  text-align: center;
  background: rgba(var(--white-rgb), 1);
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  display: flex;
  position: relative;
  overflow: hidden;
}

section.middle .card-page .card.skeleton-container .back {
  flex-direction: column;
  align-items: center;
  display: flex;
}

section.middle .card-page .card .front {
  border-bottom: var(--subtle-border);
  flex: 0 0 50%;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 40px;
  font-weight: bold;
  display: flex;
  overflow-y: auto;
}

section.middle .card-page .card .back {
  border-top: var(--subtle-border);
  opacity: 0;
  flex: 0 0 50%;
  width: 100%;
  padding-top: 40px;
  overflow-y: auto;
}

section.middle .card-page .card .back.active {
  opacity: 1;
}

section.middle .card-page .card .right-wrong-widget {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  opacity: 1;
  position: absolute;
  top: 20px;
  right: 25px;
}

section.middle .card-page .card .right-wrong-widget.hidden {
  opacity: 0;
}

section.middle .card-page .card .right-wrong-widget p {
  font-size: 2rem;
}

section.middle .card-page .button-answer-container {
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: fit-content;
  display: flex;
}

section.middle .card-page .button-answer-container button {
  width: fit-content;
  position: relative;
}

section.middle .card-page .button-answer-container .easy {
  background: rgba(var(--green-rgb), 1);
  min-width: 12%;
  max-width: fit-content;
}

section.middle .card-page .button-answer-container .hard {
  color: rgba(var(--white-rgb), 1);
  width: fit-content;
  min-width: 12%;
  background: rgba(var(--red-rgb), 1) !important;
}

section.middle .card-page .button-answer-container button span {
  color: rgba(var(--charcoal-rgb), 1);
  font-family: Mulish, BlinkMacSystemFont, sans-serif;
  font-size: 1.3rem;
  font-weight: 300;
  position: absolute;
  top: 140%;
  left: 50%;
  transform: translate(-50%, -50%);
}

section.middle .card-page .button-answer-container .hidden {
  display: none;
}

section.middle .card-page .card .no-cards-button-container {
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-top: 20px;
  display: flex;
}

section.middle .card-page ul {
  bottom: 5%;
}

form.add-card button.import {
  position: relative;
}

form.add-card button.import p.count {
  width: 100%;
  font-size: 1rem;
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
}

section.middle .profile .card h4 {
  border-bottom: 1px solid #0000001a;
  margin-bottom: 25px;
  padding-bottom: 20px;
}

section.middle .profile img#profileImagePreview {
  box-shadow: var(--thin-box-shadow);
  border-radius: 50px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
}

section.middle .leaderboard .not-following-anyone {
  margin-top: 30px;
}

section.middle .leaderboard .buttons {
  border-bottom: var(--subtle-border);
  margin-bottom: 10px;
  padding-bottom: 10px;
  display: flex;
  position: relative;
}

section.middle .leaderboard .buttons .active-underline {
  content: "";
  background: rgba(var(--charcoal-rgb), 1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 50px;
  width: 25%;
  height: 2px;
  position: absolute;
  bottom: -1px;
  left: 0%;
}

section.middle .leaderboard .buttons button {
  opacity: .3;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  margin: 0;
  padding: 0;
}

section.middle .leaderboard .buttons button:hover {
  opacity: .6;
}

section.middle .leaderboard .buttons button.active {
  opacity: 1;
  color: rgba(var(--charcoal-rgb), 1);
}

section.middle .leaderboard .leaderboard-table {
  width: 100%;
}

section.middle .leaderboard .leaderboard-table table {
  border-collapse: collapse;
  box-shadow: var(--thin-box-shadow);
  background: none;
  border-radius: 15px;
  width: 100%;
  margin-top: 20px;
  overflow: hidden;
}

section.middle .leaderboard .leaderboard-table thead {
  border-bottom: 1px solid #0000001a;
}

section.middle .leaderboard .leaderboard-table th, section.middle .leaderboard .leaderboard-table td {
  padding: 10px 20px;
}

section.middle .leaderboard .leaderboard-table th h5 {
  align-items: center;
  gap: 7px;
  display: flex;
}

section.middle .leaderboard .leaderboard-table th h5 svg {
  width: 25px;
  height: 25px;
}

section.middle .leaderboard .leaderboard-table th.rank, td.rank {
  width: 10%;
}

section.middle .leaderboard .leaderboard-table th.friend, td.friend {
  width: 60%;
  max-width: 60%;
}

section.middle .leaderboard .leaderboard-table th.xp, td.xp {
  width: 30%;
}

section.middle .leaderboard .leaderboard-table th {
  color: var(--charcoal-rgb);
  background: none;
}

section.middle .leaderboard .leaderboard-table tbody tr {
  cursor: pointer;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
}

section.middle .leaderboard .leaderboard-table tbody tr:hover, section.middle .leaderboard .leaderboard-table tbody tr:nth-child(2n):hover, section.middle .leaderboard .leaderboard-table tbody tr:nth-child(odd):hover {
  background: rgba(var(--blue-rgb), 1);
}

section.middle .leaderboard .leaderboard-table tbody tr:nth-child(2n) {
  background: rgba(var(--white-rgb), 1);
}

section.middle .leaderboard .leaderboard-table tbody tr.is-user-true {
  background: rgba(var(--blue-rgb), .3);
}

section.middle .leaderboard .leaderboard-table td.friend {
  align-items: center;
  gap: 20px;
  display: flex;
}

section.middle .leaderboard .leaderboard-table td.friend img {
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
  flex-shrink: 0;
  width: 35px;
  height: 35px;
  margin: 0;
}

section.middle .leaderboard .leaderboard-table td.friend p, section.middle .leaderboard .leaderboard-table td.friend h5 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

section.middle .leaderboard .leaderboard-table p.table-xp {
  text-align: right;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  display: flex;
}

section.middle .user .top-of-user {
  align-items: center;
  gap: 20px;
  display: flex;
}

section.middle .user .top-of-user .right {
  width: 100%;
}

section.middle .in-line {
  justify-content: space-between;
  align-items: center;
}

section.middle .in-line h3 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

section.middle .user .top-of-user img {
  box-shadow: var(--thin-box-shadow);
  border-radius: 50px;
  margin: 0 auto;
}

button.secondary.follow:hover {
  color: rgba(var(--white-rgb), 1);
  background: rgba(var(--green-rgb), 1);
}

button.secondary.unfollow:hover {
  color: rgba(var(--white-rgb), 1);
  background: rgba(var(--red-rgb), 1);
}

button.secondary.follow:hover svg, button.secondary.unfollow:hover svg {
  filter: brightness(0) saturate() invert() sepia(0%) saturate(7498%) hue-rotate(197deg) brightness(116%) contrast();
}

section.middle .user .pointer {
  cursor: pointer;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

section.middle .user .pointer:hover {
  background: rgba(var(--purple-rgb), .2);
}

section.middle .user #legend-container {
  width: 50%;
  margin-bottom: 20px;
}

.popup .followers-following ul {
  display: -ms-grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  display: grid;
}

.popup .followers-following li {
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  display: flex;
}

.popup .followers-following li img, section.middle .friends ul.user-list li img {
  -o-object-fit: cover;
  object-fit: cover;
  box-shadow: var(--thin-box-shadow);
  border-radius: 50px;
  width: 50px;
  height: 50px;
}

.popup .followers-following li .left, section.middle .friends ul.user-list li .left {
  align-items: center;
  gap: 10px;
  display: flex;
}

section.right .graph {
  width: 100%;
}

canvas#graph {
  width: 100% !important;
  height: fit-content !important;
}

section.right .centered-heading {
  text-align: center;
  width: 100%;
  position: relative;
}

section.right .centered-heading:before, section.right .centered-heading:after {
  content: "";
  text-align: center;
  background: #0000001a;
  width: 20%;
  height: 1px;
  position: absolute;
  top: 50%;
}

section.right .centered-heading:before {
  left: 0;
}

section.right .centered-heading:after {
  right: 0;
}

section.right .friend-selector {
  border: var(--subtle-border);
  background: #fff;
  border-radius: 10px 10px 0 0;
  width: 100%;
  padding: 10px 15px;
}

section.right .friend-selector .top-box {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

section.right .friend-selector .user {
  align-items: center;
  gap: 20px;
  display: flex;
}

section.right .friend-selector img {
  box-shadow: var(--box-shadow);
  border-radius: 50px;
  width: 30px;
  height: 30px;
  margin: 0;
}

section.right .friend-selector .top-box svg {
  width: 15px;
  height: 15px;
}

section.right .friend-selector .top-box svg path {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  transform-origin: center;
}

section.right .friend-selector .top-box svg path.horizontal.active {
  transform: rotate(180deg);
}

section.right .friend-selector .top-box svg path.vertical.active {
  transform: rotate(90deg);
}

section.right .friend-selector ul.bottom-box {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  opacity: 0;
  border-top: 1px solid #0000001a;
  flex-direction: column;
  gap: 0;
  max-height: 0;
  margin-top: 0;
  padding: 0 10px;
  display: flex;
  overflow-y: auto;
}

section.right .friend-selector ul.bottom-box.active {
  opacity: 1;
  gap: 15px;
  max-height: 200px;
  margin-top: 15px;
  padding: 15px 10px;
}

section.right .friend-selector ul.bottom-box li {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  box-shadow: var(--thin-box-shadow);
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  padding: 10px 25px 10px 10px;
  position: relative;
}

section.right .friend-selector p {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

section.right .friend-selector ul.bottom-box li:hover {
  background: rgba(var(--blue-rgb), 1);
}

section.right .friend-selector ul.bottom-box li.active:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E %3Cpath d='M4 12.6111L8.92308 17.5L20 6.5' stroke='%23000000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E %3C/g%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

section.right .graph .custom-legend {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

section.right .not-following-anyone, section.middle .not-following-anyone {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

.graph .custom-legend {
  border: var(--subtle-border);
  background: #fff;
  border-radius: 10px;
  flex-direction: column;
  gap: 0;
  margin-bottom: 5px;
  padding: 5px 10px;
  display: flex;
}

.graph .custom-legend li {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.graph .left-side {
  align-items: center;
  display: flex;
}

.legend-color {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  display: inline-block;
}

section.middle .friends .invite-container button.invite-link {
  margin: 10px 0 0;
}

section.middle .friends ul.user-list {
  background: rgba(var(--white-rgb), 1);
  box-shadow: var(--box-shadow);
  display: -ms-grid;
  border-radius: 20px;
  grid-template-columns: repeat(1, 1fr);
  justify-content: center;
  align-items: center;
  display: none;
}

section.middle .friends ul.user-list li {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media only screen and (width >= 100px) {
  main {
    flex-direction: column;
    display: flex;
  }

  section.left {
    box-shadow: var(--box-shadow);
    border: none;
    border-radius: 50px;
    justify-content: space-between;
    width: 90%;
    height: fit-content;
    margin: 10px auto 0;
    padding: 10px;
    position: static;
  }

  section.left .burger-wrapper {
    background: rgba(var(--white-rgb), 1);
    -webkit-transition: var(--transition);
    transition: var(--transition);
    visibility: hidden;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 85vw;
    height: 85vh;
    padding: 15px 20px;
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateX(100%)translateY(-50%);
    box-shadow: -5px 0 10px #e1e1e1;
  }

  section.left .burger-wrapper.active {
    visibility: visible;
    z-index: 999;
    transform: translateX(0)translateY(-50%);
  }

  section.middle .homepage, section.middle .deck, section.middle .profile, section.middle .leaderboard, section.middle .user, section.middle .friends {
    gap: 25px;
  }

  .card {
    padding: 10px;
  }

  section.middle ul.deck-list {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  section.middle .deck .top-page {
    flex-direction: column;
  }

  section.middle .deck .top-page .right, section.middle .deck .top-page .left {
    width: 100%;
  }

  section.middle .top .in-line {
    gap: 10px;
  }

  section.middle .user .top-of-user img {
    width: 50px;
    height: 50px;
  }

  section.right {
    height: fit-content;
  }

  section.middle .card-page {
    width: 90%;
  }

  section.middle .card-page .top-card-page {
    padding: 10px;
  }

  section.middle .card-page .card .front, section.middle .card-page .card .back {
    padding: 15px;
  }

  section.middle .card-page .card p {
    font-size: 1.6rem;
  }

  section.middle .friends ul.user-list {
    padding: 15px;
  }

  section.middle .deck ul.card-list li {
    padding: 25px;
  }

  section.middle .card-page .card .no-cards-button-container button, section.middle .card-page .card .no-cards-button-container a {
    width: 50%;
  }

  section.middle .card-page .button-answer-container .hard, section.middle .card-page .button-answer-container .easy {
    min-width: 40%;
  }
}

@media only screen and (width >= 768px) {
  section.middle .homepage, section.middle .deck, section.middle .profile, section.middle .leaderboard, section.middle .user, section.middle .friends {
    gap: 50px;
  }

  section.left nav ul {
    margin-top: 0;
  }

  .card {
    padding: 30px;
  }

  section.middle ul.deck-list {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  section.middle .deck .top-page {
    flex-direction: row;
  }

  section.middle .deck .top-page .left {
    width: 65%;
  }

  section.middle .deck .top-page .right {
    width: 35%;
    margin-bottom: 35px;
  }

  section.middle .top .in-line {
    gap: 20px;
  }

  section.middle .user .top-of-user img {
    width: 75px;
    height: 75px;
  }

  section.middle .card-page .top-card-page {
    padding: 20px;
  }

  section.middle .card-page .card p {
    font-size: 2rem;
  }

  section.middle .friends ul.user-list, section.middle .deck ul.card-list li {
    padding: 40px;
  }

  section.middle .card-page .card .no-cards-button-container button, section.middle .card-page .card .no-cards-button-container a {
    width: 20%;
  }

  section.middle .card-page .button-answer-container .hard, section.middle .card-page .button-answer-container .easy {
    min-width: 15%;
  }
}

@media only screen and (width >= 992px) {
  main {
    display: -ms-grid;
    display: grid;
  }

  section.left {
    -webkit-box-pack: normal;
    -ms-flex-pack: normal;
    border-right: var(--subtle-border);
    box-shadow: none;
    border-radius: 0;
    justify-content: normal;
    width: 100%;
    height: 100dvh;
    margin-top: 0;
    padding: 25px;
    position: sticky;
    top: 0;
    overflow: auto;
  }

  section.left .burger-wrapper {
    box-shadow: none;
    visibility: visible;
    background: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    position: relative;
    top: 0;
    right: 0;
    transform: translateX(0)translateY(0);
  }

  section.right {
    height: 100dvh;
  }
}

section.middle .homepage .top h3, ul.deck-list h5 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

ul.deck-list p.description {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

section.right .top h5 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.slider-labels {
  justify-content: space-between;
  display: flex;
}

.slider-labels span {
  flex: 0 0 20%;
}

.slider-labels span:first-child, .slider-labels span:nth-child(2) {
  text-align: left;
}

.slider-labels span:nth-child(3) {
  text-align: center;
}

.slider-labels span:nth-child(4), .slider-labels span:nth-child(5) {
  text-align: right;
}

input#numCardsPer100 {
  padding: 0;
}
/*# sourceMappingURL=app.12ec5e4a.css.map */
