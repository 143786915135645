@import url('./global.css');

:root {
   --main-app-layout: 0.9fr 2.4fr 1.1fr;
   --section-middle-margin-bottom: 50px;
   --section-padding: 25px;
}

/*  */
/* Global Styling */
/*  */

body {
   padding: 0;
   max-width: none;
}

main {
   -ms-grid-columns: var(--main-app-layout);
   grid-template-columns: var(--main-app-layout);
   min-height: 100dvh;
   width: 100%;
}

section {
   box-shadow: none;
   background: transparent;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -ms-flex-direction: column;
   flex-direction: column;
   gap: 30px;
   -webkit-box-pack: start;
   -ms-flex-pack: start;
   justify-content: flex-start;
   -webkit-box-align: start;
   -ms-flex-align: start;
   align-items: flex-start;
   padding: 0;
   width: 100%;
   padding: var(--section-padding);
   border-radius: 0;
   height: 100%;
   margin: 0;
}

section.left .burger-wrapper {
   width: 100%;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -ms-flex-direction: column;
   flex-direction: column;
   -webkit-box-pack: justify;
   -ms-flex-pack: justify;
   justify-content: space-between;
   height: 100%;
}

section.right {
   position: -webkit-sticky;
   position: sticky;
   top: 0;
   border-left: var(--subtle-border);
   overflow-y: auto;
}

.card {
   background: rgba(var(--white-rgb), 0.3);
   border-radius: 15px;
   box-shadow: var(--box-shadow);
   width: 100%;
}

/*  */
/* Global Styling */
/*  */

/*  */

/*  */
/* Nav bar on the left */
/*  */

section.left .top,
section.left .bottom {
   width: 100%;
}

section.left .top {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-box-pack: justify;
   -ms-flex-pack: justify;
   justify-content: space-between;
}

section.left .top .logo object {
   height: 45px;
   width: 45px;
   margin: 0;
}

section.left ul {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -ms-flex-direction: column;
   flex-direction: column;
   gap: 0;
   margin-top: 30px;
   -webkit-box-align: start;
   -ms-flex-align: start;
   align-items: flex-start;
}

section.left ul li {
   width: 100%;
}

section.left li a,
section.left li button.log-out {
   padding: 15px 20px;
}

section.left li a p {
   -webkit-transition: var(--transition);
   transition: var(--transition);
}

section.left li a.active {
   background: rgba(255, 255, 255, 0.4);
   border-radius: 15px;
   box-shadow: 1px 2px 5px #0000001a;
}

section.left li a.active p {
   color: rgba(var(--blue-rgb), 1);
   font-weight: 500;
}

section.left li a.active svg {
   -webkit-filter: brightness(0) saturate(100%) invert(65%) sepia(51%)
      saturate(332%) hue-rotate(181deg) brightness(96%) contrast(100%);
   filter: brightness(0) saturate(100%) invert(65%) sepia(51%) saturate(332%)
      hue-rotate(181deg) brightness(96%) contrast(100%);
}

section.left li a,
section.left li button.log-out {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   gap: 15px;
}

section.left li.has-button button {
   margin-top: 30px;
}

section.left li button.log-out {
   -webkit-box-pack: left;
   -ms-flex-pack: left;
   justify-content: left;
}

/*  */
/* Nav bar on the left */
/*  */

/*  */

/*  */
/* Middle section on the homepage (name + decks) */
/*  */

section.middle {
   margin-bottom: var(--section-middle-margin-bottom);
}

section.middle .homepage,
section.middle .deck,
section.middle .profile,
section.middle .leaderboard,
section.middle .user,
section.middle .friends {
   width: 100%;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -ms-flex-direction: column;
   flex-direction: column;
}

section.middle .deck .top-page .title-color {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   gap: 10px;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   width: 100%;
}

section.middle .deck .top-page .color-prev {
   width: 18px;
   height: 18px;
   border-radius: 50px;
   box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
   flex-shrink: 0;
   flex-grow: 0;
}

section.middle .top-page .in-line {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-box-pack: justify;
   -ms-flex-pack: justify;
   justify-content: space-between;
}

section.middle .deck .top-page p.description {
   width: 100%;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 2;
   overflow: hidden;
}

section.middle .top-of-card {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-box-pack: justify;
   -ms-flex-pack: justify;
   justify-content: space-between;
   margin-bottom: 25px;
   padding-bottom: 18px;
   border-bottom: var(--subtle-border);
}

section.middle .top-of-card h4 {
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
   overflow: hidden;
}

section.middle ul.top {
   display: -ms-grid;
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   padding: 12px 0 10px;
   border-bottom: var(--subtle-border);
   border-top: var(--subtle-border);
   gap: 0;
}

section.middle .user ul {
   grid-template-columns: repeat(4, 1fr);
}

section.middle ul.top li {
   text-align: center;
}

section.middle ul.top li:nth-child(2) {
   margin-right: 5px;
   padding-right: 5px;
   margin-left: 5px;
   padding-left: 5px;
   border-left: 1px solid rgba(0, 0, 0, 0.1);
   border-right: 1px solid rgba(0, 0, 0, 0.1);
}

section.middle .user ul.top li:nth-child(3) {
   border-right: var(--subtle-border);
   padding-right: 5px;
   margin-right: 5px;
   padding-left: 5px;
   margin-left: 5px;
}

section.middle ul.top li p {
   margin-top: -7px;
}

section.middle ul.deck-list {
   border: none;
   margin: 0;
   padding: 0;
   gap: 30px;
   display: -ms-grid;
   display: grid;
}

section.middle ul.deck-list li {
   border: none;
   margin: 0;
   padding: 0;
   text-align: left;
   background: rgba(var(--white-rgb), 1);
   -webkit-transition: var(--transition);
   transition: var(--transition);
   border-radius: 10px;
   box-shadow: var(--thin-box-shadow);
}

section.middle ul.deck-list li:hover {
   -webkit-transform: translateY(-10px);
   transform: translateY(-10px);
   box-shadow: 5px 5px 6px rgba(var(--charcoal-rgb), 0.2);
}

section.middle ul.deck-list li a,
section.middle .user ul.deck-list li {
   padding: 20px;
   width: 100%;
   display: block;
   border-radius: 6px;
   min-height: 175px;
   height: 100%;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -ms-flex-direction: column;
   flex-direction: column;
   -webkit-box-align: start;
   -ms-flex-align: start;
   align-items: flex-start;
   position: relative;
   gap: 20px;
   cursor: pointer;
   bottom: 0;
   justify-content: space-between;
}

section.middle ul.deck-list li .cards-due {
   text-align: center;
   background: #f4433640;
   border-radius: 50px;
   width: 50%;
   height: -webkit-fit-content;
   height: -moz-fit-content;
   height: fit-content;
   font-size: 1.2rem;
   position: absolute;
   bottom: 0;
   left: 50%;
   -webkit-transform: translate(-50%, 50%);
   transform: translate(-50%, 50%);
}

section.middle ul.top .stat {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   justify-content: center;
   gap: 5px;
}

/* section.middle ul.deck-list a:hover,
section.middle .user ul.deck-list li:hover {
   bottom: 10px;
   box-shadow: var(--thin-box-shadow-hover);
} */

/* section.middle .homepage a span {
   position: absolute;
   bottom: 10px;
   right: 10px;
   font-weight: normal;
} */

/*  */
/* Middle section on the homepage (name + decks) */
/*  */

/*  */

/*  */
/* Right section on the homepage  */
/*  */

section.right {
   width: 100%;
}

section.right .top {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -ms-flex-direction: column;
   flex-direction: column;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   gap: 10px;
   width: 100%;
}

section.right .top img {
   width: 55px;
   height: 55px;
   border-radius: 50px;
   margin: 0 auto;
   box-shadow: var(--thin-box-shadow);
}

section.right .top .user-stats {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   justify-content: center;
   gap: 5px;
   width: 100%;
}

section.right .top .stat {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   justify-content: center;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -ms-flex-direction: column;
   flex-direction: column;
   padding: 10px;
   border: var(--subtle-border);
   border-radius: 10px;
   text-align: center;
   width: 40%;
   background: white;
}

section.right .top .top-of-stat {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   gap: 7px;
}

section.right .top .top-of-stat i {
   font-size: 2rem;
}

section.right .top p.label {
   font-size: 1rem;
   margin-top: -5px;
}

/*  */
/* Right section on the homepage  */
/*  */

/*  */

/*  */
/* Middle section on deck page  */
/*  */

section.middle .deck .top-page {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   gap: 15px;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   width: 100%;
   align-items: center;
}

section.middle .deck .top-page .left {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   gap: 10px;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   width: 100%;
   flex-direction: column;
}

section.middle .deck .top-page .left h3 {
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
   overflow: hidden;
}

section.middle .deck .top-page .left button {
   min-width: 22px;
   min-height: 22px;
   padding: 0;
}

section.middle .deck .top-page .right {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-pack: end;
   -ms-flex-pack: end;
   justify-content: flex-end;
   gap: 10px;
   align-items: center;
}

section.middle .deck .top-page .right svg {
   cursor: pointer;
   width: 25px;
   height: 25px;
}

section.middle .deck .top-page .right button {
   width: -webkit-fit-content;
   width: -moz-fit-content;
   width: fit-content;
   margin: 0;
}

section.middle .deck ul.card-list {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -ms-flex-direction: column;
   flex-direction: column;
   padding: 0;
   border: none;
}

section.middle .deck ul.card-list li {
   position: relative;
   margin: 0;
   border-radius: 15px;
   background: rgba(var(--white-rgb), 1);
   box-shadow: var(--thin-box-shadow);
   -webkit-transition: var(--transition);
   transition: var(--transition);
   margin-bottom: 20px;
   text-align: center;
   width: 100%;
}

section.middle .deck ul.card-list li:hover {
   -webkit-transform: translateY(-10px);
   transform: translateY(-10px);
   box-shadow: 5px 5px 6px rgba(var(--charcoal-rgb), 0.2);
}

section.middle .deck ul.card-list li:last-of-type {
   margin-bottom: 0;
}

section.middle .deck ul.card-list li.delete {
   background-color: #ff5a5a;
   padding: 0;
   height: 0;
   margin: 0;
   overflow: hidden;
}

section.middle .deck ul.card-list li button {
   position: absolute;
   height: 22px;
   width: 22px;
   top: 10;
   right: 10;
   padding: 0;
}

section.middle .deck ul.card-list li .front {
   padding-bottom: 20px;
   margin-bottom: 20px;
   border-bottom: var(--subtle-border);
}

section.middle .deck ul.card-list .widget {
   position: absolute;
   top: 0;
   left: 20;
   -webkit-transform: translateY(-50%);
   transform: translateY(-50%);
   background: rgba(var(--turquoise-rgb), 1);
   box-shadow: var(--thin-box-shadow);
   padding: 1px 10px;
   border-radius: 50px;
}

section.middle .deck ul.card-list .widget p {
   color: rgba(var(--white-rgb), 1);
}

section.middle .deck .card li.in-review-true .widget {
   background: rgba(var(--pink-rgb), 1);
}

section.middle .deck .card li.future-review-true .widget {
   background: rgba(var(--green-rgb), 0.4);
}

section.middle .deck .card li.skeleton-container span.skeleton {
   margin: 7px auto;
}

/* section.middle .deck .card li .review-next {
   position: absolute;
   right: 10px;
   bottom: 10px;
} */

/*  */
/*  */

/* WHEN A USER UPLOADS AUTOMATIC CARDS */
/* Inside the deck */

p.notification {
   padding: 10px 20px;
   border-radius: 50px;
   width: 100%;
   background: rgba(var(--white-rgb), 1);
}

p.notification.hidden {
   display: none;
}

p.notification.in-progress {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   border: 2px solid rgba(var(--purple-rgb), 1);
}

p.notification.complete {
   border: 2px solid rgba(var(--green-rgb), 1);
}

p.notification.error {
   color: rgba(var(--charcoal-rgb), 1);
   border: 2px solid rgba(var(--red-rgb), 1);
   margin: 0;
}

.notification.in-progress::before {
   content: '';
   display: inline-block;
   margin-right: 10px;
}

/* Study Page */
/* Study Page */
/* Study Page */

section.middle .card-page {
   width: 75%;
   height: 100dvh;
   margin: 0 auto;
   cursor: pointer;
   display: -ms-grid;
   display: grid;
   -ms-grid-rows: 1fr 4fr 1fr;
   grid-template-rows: 1fr 4fr 1fr;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   gap: 15px;
}

section.middle .card-page .top-card-page {
   text-align: center;
   width: 100%;
   padding: 20px;
}

section.middle .card-page .top-card-page .name-deck {
   margin-bottom: 15px;
}

section.middle .card-page ul.top {
   width: 100%;
   padding: 7px 0 5px;
}

section.middle .card-page a.back {
   position: absolute;
   top: 10;
   left: 10;
}

section.middle .card-page .card {
   width: 100%;
   text-align: center;
   padding: 0;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -ms-flex-direction: column;
   flex-direction: column;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   position: relative;
   background: rgba(var(--white-rgb), 1);
   padding: 0 10px;
   height: 100%;
   overflow: hidden;
}

section.middle .card-page .card.skeleton-container .back {
   display: flex;
   align-items: center;
   flex-direction: column;
}

/* section.middle .card-page .card.isNew::before {
   content: 'New';
   position: absolute;
   top: 0;
   left: 20;
   transform: translateY(-50%);
   background: rgba(var(--blue-rgb), 1);
   box-shadow: var(--thin-box-shadow);
   padding: 1px 10px;
   border-radius: 50px;
   color: rgba(var(--white-rgb), 1);
   font-size: 1.2rem;
} */

section.middle .card-page .card .front {
   padding-bottom: 40px;
   border-bottom: var(--subtle-border);
   width: 100%;
   overflow-y: auto;
   font-weight: bold;
   -webkit-box-flex: 0;
   -ms-flex: 0 0 50%;
   flex: 0 0 50%;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   justify-content: center;
}

section.middle .card-page .card .back {
   padding-top: 40px;
   border-top: var(--subtle-border);
   width: 100%;
   overflow-y: auto;
   -webkit-box-flex: 0;
   -ms-flex: 0 0 50%;
   flex: 0 0 50%;
   opacity: 0;
}

section.middle .card-page .card .back.active {
   opacity: 1;
}

section.middle .card-page .card .right-wrong-widget {
   position: absolute;
   top: 20px;
   right: 25px;
   -webkit-transition: var(--transition);
   transition: var(--transition);
   opacity: 1;
}

section.middle .card-page .card .right-wrong-widget.hidden {
   opacity: 0;
}

section.middle .card-page .card .right-wrong-widget p {
   font-size: 2rem;
}

section.middle .card-page .button-answer-container {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   gap: 20px;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   justify-content: center;
   width: 100%;
   height: -webkit-fit-content;
   height: -moz-fit-content;
   height: fit-content;
}

section.middle .card-page .button-answer-container button {
   position: relative;
   width: -webkit-fit-content;
   width: -moz-fit-content;
   width: fit-content;
   position: relative;
}

section.middle .card-page .button-answer-container .easy {
   background: rgba(var(--green-rgb), 1);
   min-width: 12%;
   max-width: -webkit-fit-content;
   max-width: -moz-fit-content;
   max-width: fit-content;
}

section.middle .card-page .button-answer-container .hard {
   background: rgba(var(--red-rgb), 1) !important;
   color: rgba(var(--white-rgb), 1);
   min-width: 12%;
   width: -webkit-fit-content;
   width: -moz-fit-content;
   width: fit-content;
}

section.middle .card-page .button-answer-container button span {
   position: absolute;
   transform: translate(-50%, -50%);
   top: 140%;
   left: 50%;
   color: rgba(var(--charcoal-rgb), 1);
   font-family: 'Mulish', BlinkMacSystemFont, sans-serif;
   font-size: 1.3rem;
   font-weight: 300;
}

section.middle .card-page .button-answer-container .hidden {
   display: none;
}

section.middle .card-page .card .no-cards-button-container {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   justify-content: center;
   gap: 20px;
   margin-top: 20px;
   width: 100%;
}

section.middle .card-page ul {
   bottom: 5%;
}

form.add-card button.import {
   position: relative;
}

form.add-card button.import p.count {
   position: absolute;
   -webkit-transform: translateX(-50%);
   transform: translateX(-50%);
   bottom: -15px;
   left: 50%;
   font-size: 1rem;
   width: 100%;
}

/* Profile Page */
/* Profile Page */
/* Profile Page */

section.middle .profile .card h4 {
   padding-bottom: 20px;
   margin-bottom: 25px;
   border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

section.middle .profile img#profileImagePreview {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   box-shadow: var(--thin-box-shadow);
   border-radius: 50px;
   margin: 0 auto;
}

/* Leaderboard Page */
/* Leaderboard Page */
/* Leaderboard Page */

section.middle .leaderboard .not-following-anyone {
   margin-top: 30px;
}

section.middle .leaderboard .buttons {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   padding-bottom: 10px;
   margin-bottom: 10px;
   border-bottom: var(--subtle-border);
   position: relative;
}

section.middle .leaderboard .buttons .active-underline {
   position: absolute;
   content: '';
   width: 25%;
   height: 2px;
   bottom: -1px;
   background: rgba(var(--charcoal-rgb), 1);
   left: 0%;
   border-radius: 50px;
   -webkit-transition: var(--transition);
   transition: var(--transition);
}

section.middle .leaderboard .buttons button {
   width: 100%;
   opacity: 0.3;
   padding: 0;
   margin: 0;
   -webkit-transition: var(--transition);
   transition: var(--transition);
}

section.middle .leaderboard .buttons button:hover {
   opacity: 0.6;
}

section.middle .leaderboard .buttons button.active {
   opacity: 1;
   color: rgba(var(--charcoal-rgb), 1);
}

section.middle .leaderboard .leaderboard-table {
   width: 100%;
}

section.middle .leaderboard .leaderboard-table table {
   width: 100%;
   border-collapse: collapse;
   margin-top: 20px;
   box-shadow: var(--thin-box-shadow);
   border-radius: 15px;
   overflow: hidden;
   background: transparent;
}

section.middle .leaderboard .leaderboard-table thead {
   border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

section.middle .leaderboard .leaderboard-table th,
section.middle .leaderboard .leaderboard-table td {
   padding: 10px 20px;
}

section.middle .leaderboard .leaderboard-table th h5 {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   gap: 7px;
}

section.middle .leaderboard .leaderboard-table th h5 svg {
   height: 25px;
   width: 25px;
}

section.middle .leaderboard .leaderboard-table th.rank,
td.rank {
   width: 10%;
}

section.middle .leaderboard .leaderboard-table th.friend,
td.friend {
   width: 60%;
   max-width: 60%;
}

/* section.middle .leaderboard .leaderboard-table th.streak {
   width: 20%;
} */

section.middle .leaderboard .leaderboard-table th.xp,
td.xp {
   width: 30%;
}

section.middle .leaderboard .leaderboard-table th {
   background: rgba(0, 0, 0, 0.001);
   color: var(--charcoal-rgb);
}

section.middle .leaderboard .leaderboard-table tbody tr {
   cursor: pointer;
   -webkit-transition: var(--transition);
   transition: var(--transition);
   position: relative;
}

section.middle .leaderboard .leaderboard-table tbody tr:hover,
section.middle .leaderboard .leaderboard-table tbody tr:nth-child(even):hover,
section.middle .leaderboard .leaderboard-table tbody tr:nth-child(odd):hover {
   background: rgba(var(--blue-rgb), 1);
}

section.middle .leaderboard .leaderboard-table tbody tr:nth-child(even) {
   background: rgba(var(--white-rgb), 1);
}

section.middle .leaderboard .leaderboard-table tbody tr.is-user-true {
   background: rgba(var(--blue-rgb), 0.3);
}

section.middle .leaderboard .leaderboard-table td.friend {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   gap: 20px;
}

section.middle .leaderboard .leaderboard-table td.friend img {
   width: 35px;
   height: 35px;
   border-radius: 50%;
   -o-object-fit: cover;
   object-fit: cover;
   -ms-flex-negative: 0;
   flex-shrink: 0;
   margin: 0;
}

section.middle .leaderboard .leaderboard-table td.friend p {
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
   overflow: hidden;
}

section.middle .leaderboard .leaderboard-table td.friend h5 {
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
   overflow: hidden;
}

section.middle .leaderboard .leaderboard-table p.table-xp {
   text-align: right;
   display: flex;
   align-items: center;
   gap: 10px;
   justify-content: flex-end;
}

/* User Page */
/* User Page */
/* User Page */

section.middle .user .top-of-user {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   gap: 20px;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
}

section.middle .user .top-of-user .right {
   width: 100%;
}

section.middle .in-line {
   -webkit-box-pack: justify;
   -ms-flex-pack: justify;
   justify-content: space-between;
   align-items: center;
}

section.middle .in-line h3 {
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
   overflow: hidden;
}

section.middle .user .top-of-user img {
   box-shadow: var(--thin-box-shadow);
   border-radius: 50px;
   margin: 0 auto;
}

button.secondary.follow:hover {
   color: rgba(var(--white-rgb), 1);
   background: rgba(var(--green-rgb), 1);
}

button.secondary.unfollow:hover {
   color: rgba(var(--white-rgb), 1);
   background: rgba(var(--red-rgb), 1);
}

button.secondary.follow:hover svg,
button.secondary.unfollow:hover svg {
   -webkit-filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
      saturate(7498%) hue-rotate(197deg) brightness(116%) contrast(100%);
   filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7498%)
      hue-rotate(197deg) brightness(116%) contrast(100%);
}

/*  */

section.middle .user .pointer {
   cursor: pointer;
   -webkit-transition: var(--transition);
   transition: var(--transition);
}

section.middle .user .pointer:hover {
   background: rgba(var(--purple-rgb), 0.2);
}

section.middle .user #legend-container {
   width: 50%;
   margin-bottom: 20px;
}

.popup .followers-following ul {
   display: -ms-grid;
   display: grid;
   grid-template-columns: repeat(1, 1fr);
   gap: 20px;
}

.popup .followers-following li {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   gap: 15px;
   -webkit-box-pack: justify;
   -ms-flex-pack: justify;
   justify-content: space-between;
}

.popup .followers-following li img,
section.middle .friends ul.user-list li img {
   width: 50px;
   height: 50px;
   border-radius: 50px;
   -o-object-fit: cover;
   object-fit: cover;
   box-shadow: var(--thin-box-shadow);
}

.popup .followers-following li .left,
section.middle .friends ul.user-list li .left {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   gap: 10px;
}

/*  */

section.right .graph {
   width: 100%;
}

canvas#graph {
   width: 100% !important;
   height: -webkit-fit-content !important;
   height: -moz-fit-content !important;
   height: fit-content !important;
}

section.right .centered-heading {
   text-align: center;
   width: 100%;
   position: relative;
}

section.right .centered-heading::before,
section.right .centered-heading::after {
   content: '';
   position: absolute;
   width: 100%;
   text-align: center;
   height: 1px;
   background: rgba(0, 0, 0, 0.1);
   width: 20%;
   top: 50%;
}

section.right .centered-heading::before {
   left: 0;
}

section.right .centered-heading::after {
   right: 0;
}

/*  */

section.right .friend-selector {
   width: 100%;
   background: white;
   border: var(--subtle-border);
   padding: 10px 15px;
   border-radius: 10px;
   border-bottom-left-radius: 0;
   border-bottom-right-radius: 0;
}

section.right .friend-selector .top-box {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-box-pack: justify;
   -ms-flex-pack: justify;
   justify-content: space-between;
   cursor: pointer;
}

section.right .friend-selector .user {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   gap: 20px;
}

section.right .friend-selector img {
   height: 30px;
   width: 30px;
   box-shadow: var(--box-shadow);
   border-radius: 50px;
   margin: 0;
}

section.right .friend-selector .top-box svg {
   height: 15px;
   width: 15px;
}

section.right .friend-selector .top-box svg path {
   -webkit-transition: var(--transition);
   transition: var(--transition);
   -webkit-transform-origin: center;
   transform-origin: center;
}

section.right .friend-selector .top-box svg path.horizontal.active {
   -webkit-transform: rotate(180deg);
   transform: rotate(180deg);
}

section.right .friend-selector .top-box svg path.vertical.active {
   -webkit-transform: rotate(90deg);
   transform: rotate(90deg);
}

section.right .friend-selector ul.bottom-box {
   padding: 0 10px;
   border-top: 1px solid rgba(0, 0, 0, 0.1);
   margin-top: 0;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -ms-flex-direction: column;
   flex-direction: column;
   gap: 0;
   max-height: 0;
   overflow-y: auto;
   -webkit-transition: var(--transition);
   transition: var(--transition);
   opacity: 0;
}

section.right .friend-selector ul.bottom-box.active {
   padding: 15px 10px;
   margin-top: 15px;
   gap: 15px;
   max-height: 200px;
   opacity: 1;
}

section.right .friend-selector ul.bottom-box li {
   padding: 10px;
   width: 100%;
   -webkit-transition: var(--transition);
   transition: var(--transition);
   box-shadow: var(--thin-box-shadow);
   border-radius: 5px;
   cursor: pointer;
   position: relative;
   padding-right: 25px;
}

section.right .friend-selector p {
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
   overflow: hidden;
}

section.right .friend-selector ul.bottom-box li:hover {
   background: rgba(var(--blue-rgb), 1);
}

section.right .friend-selector ul.bottom-box li.active::after {
   content: '';
   position: absolute;
   right: 10px;
   top: 50%;
   -webkit-transform: translateY(-50%);
   transform: translateY(-50%);
   width: 20px;
   height: 20px;
   background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E %3Cpath d='M4 12.6111L8.92308 17.5L20 6.5' stroke='%23000000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E %3C/g%3E%3C/svg%3E");
   background-size: contain;
   background-repeat: no-repeat;
   background-position: center;
}

section.right .graph .custom-legend {
   border-top-left-radius: 0;
   border-top-right-radius: 0;
   border-top: none;
}

section.right .not-following-anyone,
section.middle .not-following-anyone {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -ms-flex-direction: column;
   flex-direction: column;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   justify-content: center;
   text-align: center;
   gap: 10px;
}

/*  */

.graph .custom-legend {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -ms-flex-direction: column;
   flex-direction: column;
   gap: 0;
   background: white;
   border: var(--subtle-border);
   border-radius: 10px;
   padding: 5px 10px;
   margin-bottom: 5px;
}

.graph .custom-legend li {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-pack: justify;
   -ms-flex-pack: justify;
   justify-content: space-between;
   width: 100%;
}

.graph .left-side {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
}

.legend-color {
   display: inline-block;
   width: 10px;
   height: 10px;
   border-radius: 50%;
   margin-right: 10px;
}

/* FRIENDS PAGE */
/* FRIENDS PAGE */
/* FRIENDS PAGE */
/* FRIENDS PAGE */

section.middle .friends .invite-container button.invite-link {
   margin: 0;
   margin-top: 10px;
}

section.middle .friends ul.user-list {
   background: rgba(var(--white-rgb), 1);
   box-shadow: var(--box-shadow);
   border-radius: 20px;
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   justify-content: center;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   display: -ms-grid;
   display: grid;
   grid-template-columns: repeat(1, 1fr);
   display: none;
}

section.middle .friends ul.user-list li {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-box-pack: justify;
   -ms-flex-pack: justify;
   justify-content: space-between;
}

/* styles for extra small devices (phones, >=100px) */
@media only screen and (min-width: 100px) {
   main {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
   }

   section.left {
      position: static;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      width: 90%;
      margin: 0 auto;
      border: none;
      box-shadow: var(--box-shadow);
      border-radius: 50px;
      margin-top: 10px;
      padding: 10px;
   }

   section.left .burger-wrapper {
      width: 85vw;
      height: 85vh;
      position: fixed;
      top: 50%;
      right: 0;
      -webkit-transform: translateX(100%) translateY(-50%);
      transform: translateX(100%) translateY(-50%);
      background: rgba(var(--white-rgb), 1);
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
      box-shadow: -5px 0px 10px #e1e1e1;
      padding: 15px 20px;
      -webkit-transition: var(--transition);
      transition: var(--transition);
      visibility: hidden;
   }

   section.left .burger-wrapper.active {
      -webkit-transform: translateX(0) translateY(-50%);
      transform: translateX(0) translateY(-50%);
      visibility: visible;
      z-index: 999;
   }

   section.middle .homepage,
   section.middle .deck,
   section.middle .profile,
   section.middle .leaderboard,
   section.middle .user,
   section.middle .friends {
      gap: 25px;
   }

   .card {
      padding: 10px;
   }

   section.middle ul.deck-list {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
   }

   section.middle .deck .top-page {
      flex-direction: column;
   }

   section.middle .deck .top-page .right {
      width: 100%;
   }

   section.middle .deck .top-page .left {
      width: 100%;
   }

   section.middle .top .in-line {
      gap: 10px;
   }

   section.middle .user .top-of-user img {
      width: 50px;
      height: 50px;
   }

   section.right {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
   }

   section.middle .card-page {
      width: 90%;
   }

   section.middle .card-page .top-card-page {
      padding: 10px;
   }

   section.middle .card-page .card .front,
   section.middle .card-page .card .back {
      padding: 15px;
   }

   section.middle .card-page .card p {
      font-size: 1.6rem;
   }

   section.middle .friends ul.user-list {
      padding: 15px;
   }

   section.middle .deck ul.card-list li {
      padding: 25px;
   }

   section.middle .card-page .card .no-cards-button-container button,
   section.middle .card-page .card .no-cards-button-container a {
      width: 50%;
   }

   section.middle .card-page .button-answer-container .hard,
   section.middle .card-page .button-answer-container .easy {
      min-width: 40%;
   }
}

/* styles for medium devices (tablets, >=768px) */
@media only screen and (min-width: 768px) {
   section.middle .homepage,
   section.middle .deck,
   section.middle .profile,
   section.middle .leaderboard,
   section.middle .user,
   section.middle .friends {
      gap: 50px;
   }

   section.left nav ul {
      margin-top: 0;
   }

   .card {
      padding: 30px;
   }

   section.middle ul.deck-list {
      -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
   }

   section.middle .deck .top-page {
      flex-direction: row;
   }

   section.middle .deck .top-page .left {
      width: 65%;
   }

   section.middle .deck .top-page .right {
      width: 35%;
      margin-bottom: 35px;
   }

   section.middle .top .in-line {
      gap: 20px;
   }

   section.middle .user .top-of-user img {
      width: 75px;
      height: 75px;
   }

   section.middle .card-page .top-card-page {
      padding: 20px;
   }

   section.middle .card-page .card p {
      font-size: 2rem;
   }

   section.middle .friends ul.user-list {
      padding: 40px;
   }

   section.middle .deck ul.card-list li {
      padding: 40px;
   }

   section.middle .card-page .card .no-cards-button-container button,
   section.middle .card-page .card .no-cards-button-container a {
      width: 20%;
   }

   section.middle .card-page .button-answer-container .hard,
   section.middle .card-page .button-answer-container .easy {
      min-width: 15%;
   }
}

/* styles for large devices (desktops, >=992px) */
@media only screen and (min-width: 992px) {
   main {
      display: -ms-grid;
      display: grid;
   }

   section.left {
      position: -webkit-sticky;
      position: sticky;
      height: 100dvh;
      overflow: auto;
      top: 0;
      -webkit-box-pack: normal;
      -ms-flex-pack: normal;
      justify-content: normal;
      width: 100%;
      border-right: var(--subtle-border);
      box-shadow: none;
      border-radius: 0;
      margin-top: 0;
      padding: 25px;
   }

   section.left .burger-wrapper {
      width: 100%;
      height: 100%;
      position: relative;
      top: 0;
      right: 0;
      -webkit-transform: translateX(0) translateY(0);
      transform: translateX(0) translateY(0);
      background: none;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      box-shadow: none;
      padding: 0;
      visibility: visible;
   }

   section.right {
      height: 100dvh;
   }
}

/* styles for extra large devices (large desktops, >=1200px) */
@media only screen and (min-width: 1200px) {
}

/* styles for extra extra large devices (full-screen monitors, >=1400px) */
@media only screen and (min-width: 1400px) {
}

/*  */
/*  */
/*  */

section.middle .homepage .top h3 {
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
   overflow: hidden;
}

ul.deck-list h5 {
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
   overflow: hidden;
}

ul.deck-list p.description {
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 2;
   overflow: hidden;
}

section.right .top h5 {
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
   overflow: hidden;
}

.slider-labels {
   display: flex;
   justify-content: space-between;
}

.slider-labels span {
   flex-grow: 0;
   flex-shrink: 0;
   flex-basis: 20%;
}

.slider-labels span:nth-child(1),
.slider-labels span:nth-child(2) {
   text-align: left;
}

.slider-labels span:nth-child(3) {
   text-align: center;
}

.slider-labels span:nth-child(4),
.slider-labels span:nth-child(5) {
   text-align: right;
}

input#numCardsPer100 {
   padding: 0;
}

/* Custom slider styles */
